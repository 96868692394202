enum Steps {
  Login,
  MembershipReview,
  MembershipInformation,
  MembershipAddon,
  Tickets,
  TicketConfirmation,
  Donation,
  Addons,
  Billing,
  Review,
  Summary,
  Done,
}

export default Steps;
