import { useEffect } from "react";

import ContactForm from "./ContactForm";
import PaymentForm from "./PaymentForm";

import { goToNextStep } from "..";
import StepComponent, { Step, StepComponentProps, StepSubmitHandler } from "../Step";
import Steps from "../Steps";

import getCartTotal from "../../formatters/getCartTotal";
import getLocalizedText from "../../formatters/getLocalizedText";
import useFormikSchemaContext from "../../hooks/useFormikSchemaContext";
import checkResidentLocation from "../Login/checkResidentLocation";

const onSubmit: StepSubmitHandler = async ({
  appConfiguration,
  helpers,
  onStep,
  values
}) => {
  const residentTicketTypes = appConfiguration
    ?.ticket_types
    .filter(({ show_residents, show_non_residents }) => show_residents && !show_non_residents)
    .map(({ applies_to_id }) => applies_to_id);
  
  if(residentTicketTypes?.length) {
    const hasResidentTickets = !!values
      .tickets
      .find(({ ticketingTypeID }) => residentTicketTypes.includes(ticketingTypeID));

    if(hasResidentTickets && !checkResidentLocation(appConfiguration, values.zip)) {
      helpers.setFieldError("zip", getLocalizedText(appConfiguration, "notAResidentZIPCode", "Not a resident ZIP code."));
      return;
    }
  }

  await goToNextStep(values.stepNumber, values.stepsEnabled, helpers.setFieldValue, onStep);
};

const Component = (props: StepComponentProps) => {
  const { setFieldValue } = useFormikSchemaContext();
  const { cartData } = props;

  const {
    requiredTotal
  } = getCartTotal(cartData);

  useEffect(() => {
    setFieldValue("paymentRequired", requiredTotal !== null && requiredTotal > 0);
  }, [requiredTotal, setFieldValue]);

  return (
    <StepComponent {...props}>
      {requiredTotal
        ? <PaymentForm {...props} />
        : <ContactForm {...props} />
      }
    </StepComponent>
  )
}

const Billing: Step = {
  Component,
  defaultLabel: "Billing",
  isMembershipStep: true,
  isTicketStep: true,
  labelKey: "billing",
  onSubmit,
  stepNumber: Steps.Billing
}

export default Billing;
