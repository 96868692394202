import { FC, useEffect } from "react";

import DonationOption from "./DonationOption";
import DonorRecognitionOption from "./DonorRecognitionOption";

import StepComponent, { Step, StepComponentProps } from "../Step";
import Steps from "../Steps";

import Input from "../../components/Input";
import TextContent from "../../components/TextContent";
import ToggleContainer from "../../components/ToggleContainer";
import useFormikSchemaContext from "../../hooks/useFormikSchemaContext";
import getPriceFormatter from "../../formatters/getPriceFormatter";
import getLocalizedText from "../../formatters/getLocalizedText";
import SkipButton from "../../components/SkipButton";

const Component: FC<StepComponentProps> = (props) => {
  const { setFieldValue, values } = useFormikSchemaContext();
  const { appConfiguration } = props;
  const fixedDonations = appConfiguration?.addons.filter(addon => addon.type === "fixedDonation");
  const customDonation = appConfiguration?.addons.find(addon => addon.type === "donation");
  const selectedDonation = appConfiguration?.addons.find(addon => addon.addon_id === values.donationID);

  const format = getPriceFormatter(appConfiguration?.locale);

  useEffect(() => {
    setFieldValue("donationType", selectedDonation?.type || "");
  }, [selectedDonation?.type, setFieldValue]);

  return (
    <StepComponent 
      {...props}
      beforeSubmitButton={<SkipButton 
        {...props} 
        fieldValues={{
          donationID: "",
          donorRecognitionType: "self",
        }}
      />}
      defaultHeaderLabel="Donation"
      headerLabelKey="donation"
    >
      <TextContent
        appConfiguration={appConfiguration}
        contentKey="beforeDonation"
      />
      {fixedDonations?.length || customDonation
        ? (
          <>
            <ul className="donations__list">
              {fixedDonations?.map(donation => (
                <DonationOption
                  addon={donation}
                  appConfiguration={appConfiguration}
                  format={format}
                  key={donation.addon_id}
                />
              ))}
              {customDonation && <DonationOption
                addon={customDonation}
                appConfiguration={appConfiguration}
                format={format}
              />}
            </ul>
            <ToggleContainer
              renderWhenHidden={true}
              visible={selectedDonation?.type === "donation"}
            >
              <Input
                name="customDonationAmount"
                label={getLocalizedText(appConfiguration, "customDonationAmountLabel", "Donation Amount")}
                pattern="^[0-9.$]+$"
              />
            </ToggleContainer>
            <ToggleContainer
              renderWhenHidden={true}
              visible={!!selectedDonation}
            >
              <TextContent
                contentKey="donationRecognitionHeader"
                defaultValue="Add a Recognition (Optional)"
                tag="h4"
              />
              <ul className="donations__donorRecognition__list">
                <DonorRecognitionOption
                  defaultLabel={getLocalizedText(appConfiguration, "donorRecognitionDescriptionSelf", "In my name (Enter your name during payment)")}
                  donorRecognitionType="self"
                />
                <DonorRecognitionOption
                  defaultLabel={getLocalizedText(appConfiguration, "donorRecognitionDescriptionAnonymous", "Anonymous")}
                  donorRecognitionType="anonymous"
                />
                <DonorRecognitionOption
                  defaultLabel={getLocalizedText(appConfiguration, "donorRecognitionDescriptionOrganization", "On behalf of an organization")}
                  donorRecognitionType="organization"
                />
              </ul>
              <Input
                as="textarea"
                name="donationNotes"
                placeholder={getLocalizedText(appConfiguration, "donationNotesPlaceholder", "Notes (optional).")}
              />
            </ToggleContainer>
          </>
        )
        : (
          <>
            <p>No donation options available.</p>
          </>
        )
      }
      <TextContent
        appConfiguration={appConfiguration}
        contentKey="afterDonation"
      />
    </StepComponent>
  );
}

const Donation: Step = {
  Component,
  defaultLabel: "Donate",
  isMembershipStep: true,
  isTicketStep: true,
  labelKey: "donate",
  liveCart: true,
  stepNumber: Steps.Donation,
}

export default Donation;
