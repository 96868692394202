import { useCallback, useState } from "react";

import { AppConfiguration } from "../fetchers/getAppConfiguration";
import checkFraudShield, { CheckFraudShieldArgs, WorkflowFlowParams } from "../fetchers/checkFraudShield";

export type useFraudShieldArgs = {
    appConfiguration: AppConfiguration | null | undefined;
}

export type UseFraudShield = ReturnType<typeof useFraudShield>;

export default function useFraudShield({
    appConfiguration
}: useFraudShieldArgs) {
    const modalID = "fraud-shield-modal";
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const boundCheckFraudShield = useCallback(async (params: WorkflowFlowParams) => {
        if(!appConfiguration?.use_3ds) {
            return false;
        }

        let args: CheckFraudShieldArgs = {
            ...params,
            mid: appConfiguration.acme_merchant_id!,
            publishableKey: appConfiguration.acme_publishable_key!,
            container: {
                selector: () => {
                    setModalIsOpen(true);
            
                    return document.querySelector(`#${modalID}`)!;
                },
            }
        };

        if(appConfiguration.acme_api_url.includes("sand10")) {
            args = {
                ...args,
                baseUrl: "https://sand10-api.acmepayments.net",
                environmentName: "sandbox",
                publishableKey: "xyz",
            };
        }

        const result = await checkFraudShield(args);

        setModalIsOpen(false);

        return result;
    }, [
        appConfiguration?.acme_api_url,
        appConfiguration?.acme_merchant_id,
        appConfiguration?.acme_publishable_key,
        appConfiguration?.use_3ds
    ]);

    return {
        checkFraudShield: boundCheckFraudShield,
        fraudShieldModalIsOpen: modalIsOpen,
        fraudShieldModalID: modalID,
        setFraudShieldModalIsOpen: setModalIsOpen,
    }
}