import { goToPreviousStep } from "..";
import getLocalizedText from "../../formatters/getLocalizedText";
import useFormikSchemaContext from "../../hooks/useFormikSchemaContext";

import { StepComponentProps } from "../Step";

interface CodedError {
  code: number;
  message: string;
  type: string | null;
}

export default function CheckoutError({
  appConfiguration,
  checkoutError,
  onStep,
}: StepComponentProps) {
  const { values, setFieldValue } = useFormikSchemaContext();

  if(!checkoutError) {
    return "";
  }

  let errorMessage = "";
  let goToBilling = false;

  if(typeof checkoutError === "object" && "code" in checkoutError) {
    switch((checkoutError as CodedError).code) {
      case 419:
      case 3001: errorMessage = getLocalizedText(appConfiguration, "sessionExpiredMessage", "Your session has expired. Please refresh the page and submit the order again."); break;
      case 483: 
      case 6002:
      case 6004:
      case 6010:
      case 6011:
      case 6110:
        errorMessage = getLocalizedText(appConfiguration, "paymentProcessingErrorMessage", "Could not process payment. Please check your billing information and try again.");
        goToBilling = true;
        break;
      case 6005: 
        errorMessage = getLocalizedText(appConfiguration, "duplicateTransactionError", "This transaction has been flagged as a duplicate. Check your email to see if the order has already been completed.");
        goToBilling = true;
        break;
      case 6006: 
        errorMessage = getLocalizedText(appConfiguration, "insufficientMembershipError", "You do not have a sufficient membership level to make this purchase.");
        goToBilling = true;
        break;
      case 6100:
      case 6101:
      case 6102:
      case 6103:
      case 6104:
      case 6106:
      case 6107:
      case 6111:
      case 6112:
      case 6113:
        errorMessage = getLocalizedText(appConfiguration, "invalidCreditCardError", "Invalid credit card information. Please check your billing information and try again.");
        goToBilling = true;
        break;
      case 6105:
        errorMessage = getLocalizedText(appConfiguration, "creditCardExpiredError", "Your credit card has expired. Please choose a different method of payment.");
        goToBilling = true;
        break;
      case 6108:
        errorMessage = getLocalizedText(appConfiguration, "declinedCreditCardError", "Your credit card was declined.");
        goToBilling = true;
        break;
      case 500: errorMessage = getLocalizedText(appConfiguration, "serverErrorMessage", "There was a problem submitting the order. Please try again. If the problem persists, please refresh the page."); break;
      case 3009: errorMessage = getLocalizedText(appConfiguration, "systemBusyMessage", "The system is experiencing unusually high traffic. Please try again in a few minutes."); break;
      case 5157: errorMessage = getLocalizedText(appConfiguration, "captchaFailedMessage", "CAPTCHA failed. Check that CAPTCHA is complete and try again. If the error persists, refresh the page and submit the order again."); break;
      default: 
        errorMessage = getLocalizedText(appConfiguration, "defaultCheckoutErrorMessage", "There was a problem placing the order. Please check your billing information and try again."); 
        goToBilling = true;
        break;
    }
  }

  return (
    <div className="checkoutError">
      <p className="checkoutError__message">{errorMessage}</p>
      {goToBilling && (
        <p className="checkoutError__goToBilling">
          <a
            href="#"
            onClick={async (e) => {
              e.preventDefault();
              e.stopPropagation();

              await goToPreviousStep(
                values.stepNumber,
                values.stepsEnabled,
                setFieldValue,
                onStep
              );
            }}
          >{getLocalizedText(appConfiguration, "goToBilling", "Go to billing")}</a>.
        </p>
      )}
    </div>
  );
}