import { useCallback, useReducer, useRef } from "react";

import postCORSCheckout, { acmeCORSSharedArgs, postCORSCheckoutMainArgs, Order } from "../fetchers/postCORSCheckout";
import postClientCheckoutLog, { postClientCheckoutLogMainArgs, postClientCheckoutLogResponse } from "../fetchers/postClientCheckoutLog";

export interface useCORSCheckoutArgs extends Omit<acmeCORSSharedArgs, "recaptchaToken"> {
  apiURL: string | null | undefined;
  authorizationToken: string | null | undefined;
}

export interface PostCORSCheckoutArgs extends postCORSCheckoutMainArgs {
  recaptchaToken?: string | null;
}

export type PostCORSCheckoutFunction = (checkout: PostCORSCheckoutArgs) => Promise<Order | Response | null>;
export type PostClientCheckoutLogFunction = (args: postClientCheckoutLogMainArgs) => Promise<null | postClientCheckoutLogResponse>;

export type UseCORSCheckout = ReturnType<typeof useCORSCheckout>;

interface CORSCheckoutState {
  checkoutError: unknown | null,
  checkoutOrder: Order | null,
  checkoutRequest: PostCORSCheckoutArgs | null,
  checkoutResponse: Response | null,
  isCheckoutLoading: boolean;
  isCheckoutValidating: boolean;
}

const defaultCORSCheckoutState: CORSCheckoutState = {
  checkoutOrder: null,
  checkoutRequest: null,
  checkoutResponse: null,
  checkoutError: null,
  isCheckoutLoading: false,
  isCheckoutValidating: false,
}

function corsCheckoutStateReducer(state: CORSCheckoutState, newState: Partial<CORSCheckoutState>) {
  return {
    ...state,
    ...newState,
  };
}

export default function useCORSCheckout({
  apiURL,
  authorizationToken,
  corsURL,
  publicKey,
  tenantID,
}: useCORSCheckoutArgs) {
  const lastOrderRef = useRef<Order | null>(null);
  const [state, dispatch] = useReducer(corsCheckoutStateReducer, defaultCORSCheckoutState);

  const {
    checkoutOrder,
    checkoutRequest,
    checkoutResponse,
    checkoutError,
    isCheckoutLoading,
    isCheckoutValidating,
  } = state;

  const boundPostCORSCheckout: PostCORSCheckoutFunction = useCallback(async (checkout) => {
    if(lastOrderRef.current) {
      return lastOrderRef.current;
    }

    let response: Response | null = null;
    let order: Order | null = null;

    try {
      dispatch({
        checkoutError: null,
        checkoutRequest: checkout,
        isCheckoutLoading: true,
        isCheckoutValidating: true 
      });
      
      response = await postCORSCheckout({
        corsURL,
        publicKey,
        tenantID,
        ...checkout,
      });

      if(response?.ok) {
        const responseData = await response.json();

        if(responseData) {
          order = responseData as Order;
          lastOrderRef.current = order;
        }

        try {
          await postClientCheckoutLog({
            apiURL: apiURL || "",
            authorizationToken: authorizationToken || "",
            requestPayload: checkout as unknown as Record<string, null>,
            responsePayload: responseData,
            responseStatus: 200
          });
        } catch(error) {
          console.error("postClientCheckoutLog", error);
        }
      } else {
        throw new Error(response ? `(${response.status}) ${response.statusText}` : "No response.");
      }

      dispatch({
        checkoutError: null,
        checkoutOrder: order,
        checkoutResponse: response,
        isCheckoutLoading: false,
        isCheckoutValidating: false,
      });
    } catch(error) {
      let responsePayload: Record<string, unknown> | null = null;

      try {
        responsePayload = (await response?.json()) || null;
        
        await postClientCheckoutLog({
          apiURL: apiURL || "",
          authorizationToken: authorizationToken || "",
          requestPayload: checkout as unknown as Record<string, null>,
          responsePayload,
          responseStatus: response?.status || 400,
        });
      } catch(error) {
        console.error("postClientCheckoutLog", error);
      }

      console.error("postCORSCheckout", error);

      const checkoutError = (responsePayload?.code && responsePayload?.message)
        ? {
          ...responsePayload,
          error,
        }
        : error;

      dispatch({
        checkoutError,
        checkoutOrder: order,
        checkoutResponse: response,
        isCheckoutLoading: false,
        isCheckoutValidating: false,
      });
    }

    return order || response;
  }, [
    apiURL,
    authorizationToken,
    corsURL,
    publicKey,
    tenantID
  ]);

  const boundPostClientCheckoutLog: PostClientCheckoutLogFunction = useCallback(async (mainArgs) => {
    if(!apiURL || !authorizationToken) {
      return null;
    }
    
    const result = await postClientCheckoutLog({
      apiURL,
      authorizationToken,
      ...mainArgs,
    });

    return result;
  }, [apiURL, authorizationToken]);

  return {
    checkoutOrder,
    checkoutError,
    checkoutRequest,
    checkoutResponse,
    isCheckoutLoading,
    isCheckoutValidating,
    postClientCheckoutLog: boundPostClientCheckoutLog,
    postCORSCheckout: boundPostCORSCheckout,
  };
}