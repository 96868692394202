import StepComponent, { Step, StepComponentProps } from "./Step";
import Steps from "./Steps";

import LoaderContainer from "../components/LoaderContainer";
import TextContent from "../components/TextContent";
import Input from "../components/Input";
import SkipButton from "../components/SkipButton";
import getPriceFormatter from "../formatters/getPriceFormatter";

const Component = (props: StepComponentProps) => {
  const {
    appConfiguration,
    getMemberLevelByID,
    memberLevelDataError,
    memberLevelDataIsLoading,
    memberLevelDataIsValidating,
  } = props;

  const formatPrice = getPriceFormatter(appConfiguration?.locale || "en-US", true);

  const mappedLevels = appConfiguration?.membership_addons?.map(addon => {
    const level = getMemberLevelByID(addon.membership_level_id);

    return {
      id: addon.membership_level_id,
      label: addon.label || level?.name || "",
      description: addon.description || level?.description || "",
      image: addon.image || level?.images.length && level?.images[0].screen,
      price: formatPrice(level?.offerings[0].priceLists[0].prices[0].price || 0),
    }
  });

  return (
    <StepComponent
      {...props}
      beforeSubmitButton={<SkipButton 
        {...props} 
        fieldValues={{
          membershipAddonLevelID: "",
        }}
      />}
    >
      <LoaderContainer
        error={memberLevelDataError}
        isLoading={memberLevelDataIsLoading}
        isValidating={memberLevelDataIsValidating}
        render={() => (
          <>
            {mappedLevels?.length
              ? (
                <ul className="membershipAddon__list">
                  {mappedLevels.map(({ description, id, image, label, price }) => (
                    <li
                      key={id}
                      className="membershipAddon__list__item"
                    >
                      <div className="membershipAddon__list__item__main">
                        <Input
                          deselectRadioOnClick={true}
                          label={label}
                          name="membershipAddonLevelID"
                          type="radio"
                          value={id}
                        />
                        <span className="membershipAddon__list__item__price">{price}</span>
                      </div>
                      {(image || description) &&
                        (
                          <div className="membershipAddon__list__item__secondary">
                            {image && (
                              <img
                                className="membershipAddon__list__item__image"
                                alt={label}
                                src={image}
                              />
                            )}

                            {description && (
                              <div 
                                className="membershipAddon__list__item__description"
                                dangerouslySetInnerHTML={{ __html: description }}
                              />
                            )}
                          </div>
                        )
                      }
                    </li>
                  ))}
                </ul>
              )
              : (
                <TextContent
                  appConfiguration={appConfiguration}
                  contentKey="noMembershipAddonOptionsMessage"
                  defaultValue="No add-on options available. Please continue to checkout."
                  tag="p"
                />
              )
            }
          </>
        )}
      />
    </StepComponent>
  );
};

const MembershipAddon: Step = {
  Component,
  defaultLabel: "Add-ons",
  isMembershipStep: true,
  isTicketStep: false,
  labelKey: "addOns",
  liveCart: true,
  stepNumber: Steps.MembershipAddon,
}

export default MembershipAddon;