import useFormikSchemaContext from "../../hooks/useFormikSchemaContext";
import { StepComponentProps } from "../Step";
import Modal from "../../components/Modal";

export default function FraudShieldModal({
    appConfiguration,
    fraudShieldModalID,
    fraudShieldModalIsOpen,
    setFraudShieldModalIsOpen,
}: StepComponentProps) {
    const { isSubmitting } = useFormikSchemaContext();

    if(!appConfiguration?.use_3ds) {
        return "";
    }

    return (
        <Modal
            disableCloseOnOutsideClick={true}
            id={fraudShieldModalID}
            isOpen={isSubmitting && fraudShieldModalIsOpen}
            setIsOpen={setFraudShieldModalIsOpen}
        />
    );
}