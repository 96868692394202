import getAuthorizedAPIData, { APIResponse, getAuthorizedAPIDataSharedArgs } from "./getAuthorizedAPIData";

export interface MemberLevelRule {
  numberOfCardHolders: number;
  canGift: boolean;
  startBeginningOfMonth: boolean;
  expireEndOfMonth: boolean;
}

export interface MemberLevelBenefit {
  description: string;
}

export interface MemberLevelPersonType {
  id: string;
  name: string;
  description: string;
  active: boolean;
  type: string;
  displayOrder: number;
}

export interface MemberLevelSalesChannel {
  name: string;
  channel: string;
  enabled: boolean;
}

export interface MemberlevelPricePoint {
  id: string;
  name: string;
  description: string;
  active: boolean;
  type: string;
  displayOrder: number;
}

export interface MemberLevelPrice {
  personType: MemberLevelPersonType;
  price: string;
  primePrice: string;
  salesChannels: MemberLevelSalesChannel[];
  displayOrder: number;
  pricePoint: MemberlevelPricePoint;
  discountedPrice: string;
}

export interface MemberLevelPriceList {
  id: string;
  name: string;
  description: string;
  prices: MemberLevelPrice[];
  priceListType: string;
  startTime: string;
}

export interface MemberLevelConfirmationEmailRestriction {
  saleChannel: string;
  canSendConfirmationEmail: boolean;
}

export interface MemberLevelAction {
  action: string;
  ruleIds: string[];
  salesChannels: MemberLevelSalesChannel[];
  confirmationEmailRestrictions: MemberLevelConfirmationEmailRestriction[];
  printMembershipCards: boolean;
  includeDigitalMemberCardsInConfirmationEmail: boolean;
  confirmationMessage: string;
}

export interface MemberLevelOffering {
  id: string;
  name: string;
  subtitle: string;
  description: string;
  duration: number;
  benefits?: MemberLevelBenefit[];
  rules: MemberLevelRule;
  priceLists: MemberLevelPriceList[];
  actions: MemberLevelAction[];
  autorenewalType: string;
  externalMembershipPriceRequired: boolean;
}

export interface MemberLevelImage {
  id: string;
  primary: boolean;
  thumbnail: string;
  highRes: string;
  screen: string;
  preview: string;
}

export interface MemberLevel {
  id: string;
  name: string;
  description: string;
  shortDescription: string;
  rules: MemberLevelRule;
  benefits?: MemberLevelBenefit[];
  offerings: MemberLevelOffering[];
  images: MemberLevelImage[];
  salesChannels: MemberLevelSalesChannel[];
  state: string;
  membershipLevelGroup: string;
  displayOrder: number;
  subtitle: string;
  auxiliary: boolean;
}

export interface getMemberLevelDataMainArgs {

}

export type getMemberLevelDataArgs = getAuthorizedAPIDataSharedArgs & getMemberLevelDataMainArgs;
export type MemberLevelData = MemberLevel[];

export default async function getMemberLevelData({
  apiURL,
  authorizationToken,
}: getMemberLevelDataArgs): APIResponse<MemberLevelData> {
  const endPoint = "api/membership/levels";

  return getAuthorizedAPIData({
    apiURL,
    authorizationToken,
    endPoint,
  });
}