import { StepComponentProps } from "../steps/Step";
import SubmitButton from "./SubmitButton";
import useFormikSchemaContext from "../hooks/useFormikSchemaContext";
import getLocalizedText from "../formatters/getLocalizedText";

export interface SkipButtonProps extends StepComponentProps {
  fieldValues: Record<string, unknown>;
}

export default function SkipButton({
  appConfiguration,
  fieldValues,
}: SkipButtonProps) {
  const { values, setValues } = useFormikSchemaContext();
  
  return (
    <SubmitButton
      ignoreInvalid={true}
      loadingLabel={""}
      onClick={async () => {
        await setValues({
          ...values,
          ...fieldValues,
        });
      }}
    >
      {getLocalizedText(appConfiguration, "skipButtonLabel", "Skip")}
    </SubmitButton>
  );
}