import { WorkflowFlowParams } from "../../fetchers/checkFraudShield";

import CheckoutError from "./CheckoutError";
import FraudShieldModal from "./FraudShieldModal";
import PromoCode from "./PromoCode";
import Recaptcha from "./Recaptcha";

import StepComponent, { Step, StepComponentProps, StepSubmitHandler } from "../Step";
import Steps from "../Steps";

import AddOnsSummary from "../../components/AddOnsSummary";
import TicketConfirmation from "../../components/TicketConfirmation";
import { goToNextStep } from "..";
import { PostCORSCheckoutArgs } from "../../hooks/useCORSCheckout";
import { getVoucherCodeDistribution } from "../../hooks/useScheduleCartUpdate";
import getCartTotal from '../../formatters/getCartTotal';

const onSubmit: StepSubmitHandler = async ({
  appConfiguration,
  checkFraudShield,
  countryData,
  cartData,
  helpers,
  onCheckout,
  onStep,
  postClientCheckoutLog,
  postCORSCheckout,
  redeemVoucher,
  ticketData,
  ticketDate,
  updateSold,
  values,
  voucherData,
}) => {
  let checkoutArgs: PostCORSCheckoutArgs = {
    address1: values.streetAddress1,
    address2: values.streetAddress2,
    billingEmail: values.email,
    email: values.email,
    city: values.city,
    contactFirstName: values.firstName,
    contactLastName: values.lastName,
    contactFullName: [values.firstName || "", values.lastName || ""].filter(v => v).join(" "),
    country: values.country,
    phoneNumber: values.phoneNumber,
    recaptchaToken: appConfiguration?.recaptcha_site_key_v3 && values.recaptchaV3Token,
    shoppingCartId: cartData?.id,
    state: values.state,
    zipCode: values.zip,
    uuid: values.uuid,
  };

  if(values.paymentRequired) {
    const cardExpMonth = values.expirationMonth;

    checkoutArgs = {
      ...checkoutArgs,
      billingAddress1: values.streetAddress1,
      billingAddress2: values.streetAddress2,
      billingCity: values.city,
      billingCountry: values.country,
      billingFirstName: values.firstName,
      billingLastName: values.lastName,
      billingPhoneNumber: values.phoneNumber,
      billingState: values.state,
      billingZipCode: values.zip,
      cvc: values.securityCode,
      expDate: cardExpMonth + values.expirationYear.toString().substring(2),
      manualEntryCardNumber: values.cardNumber,
    }

    if(appConfiguration?.use_3ds) {
      let fraudShieldArgs: WorkflowFlowParams | null = null;
      let fraudShieldResult: Awaited<ReturnType<typeof checkFraudShield>> = false;

      try {
        fraudShieldArgs = {
          amount: getCartTotal(cartData).total!.toFixed(2),
          externalId: checkoutArgs.uuid!,
          billingAddress1: checkoutArgs.billingAddress1,
          billingAddress2: checkoutArgs.billingAddress2,
          billingCity: checkoutArgs.billingCity,
          billingState: checkoutArgs.billingState,
          billingCountryCode: countryData
            ?.find(country => country.name === checkoutArgs.billingCountry)
            ?.numeric_code || "840",
          billingFirstName: checkoutArgs.billingFirstName!,
          billingLastName: checkoutArgs.billingLastName!,
          billingPostalCode: checkoutArgs.billingZipCode!,
          billingPhone: checkoutArgs.billingPhoneNumber,
          cardExpMonth,
          cardExpYear: values.expirationYear.toString(),
          cardNumber: checkoutArgs.manualEntryCardNumber!,
          email: checkoutArgs.email,
        };

        fraudShieldResult = await checkFraudShield(fraudShieldArgs);
        
        if(!fraudShieldResult) {
          throw new Error("No response from fraud shield API.");
        }

        checkoutArgs.enhancedSecurity = {
          acmeSessionId: fraudShieldResult.acmeSessionId,
        }
      } catch(error) {
        console.error(error);

        if(fraudShieldArgs) {
          await postClientCheckoutLog({
            requestPayload: fraudShieldArgs as unknown as Record<string, unknown>,
            responsePayload: fraudShieldResult ? (fraudShieldResult as unknown as Record<string, unknown>) : null,
            responseStatus: null
          });
        }

        throw new Error("Could not verify transaction. Please check details and try again.");
      }
    }
  }

  const order = await postCORSCheckout(checkoutArgs);

  if(order && "id" in order && order.id) {
    try {
      console.log("Ticket sale quantity update");

      if(cartData?.items?.length) {
        for(const item of cartData.items) {
          const ticket = ticketData?.find(ticket => ticket.event_id === item.eventId);

          if(ticket) {
            try {
              await updateSold(ticket.id.toString(), item.quantity);
            } catch(error) {
              console.error("udpateSold", error);
            }
          }
        }
      }

      console.log("Combo sale quantity update");

      if(cartData?.comboItems?.length) {
        for(const comboItem of cartData.comboItems) {
          for(const comboTicket of comboItem.tickets) {
            const comboEvent = comboItem.events.find(comboEvent => comboEvent.eventTemplateId === comboTicket.eventTemplateId);
            const ticket = ticketData?.find(ticket => ticket.event_id === comboEvent?.eventId);
  
            if(ticket) {
              try {
                await updateSold(ticket.id.toString(), comboTicket.quantity);
              } catch(error) {
                console.error("udpateSold", error);
              }
            }
          }
        }
      }

      if(order?.id && values.voucherCodes.length) {
        const usedVoucherCodes = Object
          .values(getVoucherCodeDistribution({
            ticketData,
            values,
            voucherData
          }))
          .flat()
          .map(vouchers => vouchers.voucherCode);
          
        if(usedVoucherCodes.length) {
          console.log("Voucher redemption update");
          
          for(const usedVoucherCode of usedVoucherCodes) {
            try {
              await redeemVoucher({
                orderNumber: order.orderNumber,
                visitDate: ticketDate || new Date(),
                voucherCode: usedVoucherCode,
              });
            } catch(error) {
              console.error("redeemVoucher", error);
            }
          }
        }
      }
    } catch(error) {
      console.error("Error uploading order data", error);
    }

    if(onCheckout) {
      try {
        onCheckout(order, checkoutArgs);
      } catch (error) {
        console.error("onCheckout", error, order, checkoutArgs);
      }
    }

    await goToNextStep(values.stepNumber, values.stepsEnabled, helpers.setFieldValue, onStep);
  } else {
    if(onCheckout) {
      try {
        onCheckout(null, checkoutArgs);
      } catch (error) {
        console.error("onCheckout", error, order, checkoutArgs);
      }
    }

    console.error("Missing order ID", order);
  }
};

const Component = (props: StepComponentProps) => {
  return (
    <StepComponent
      {...props}
      submitButtonLabel="Place Order"
    >
      <TicketConfirmation {...props} showTotal={false} />
      <AddOnsSummary {...props} showContent={false} showIfBlank={false} />
      <PromoCode {...props} />
      <Recaptcha {...props} />
      <FraudShieldModal {...props} />
      <CheckoutError {...props} />
    </StepComponent>
  )
};

const Review: Step = {
  Component,
  defaultLabel: "Review",
  isMembershipStep: true,
  isTicketStep: true,
  labelKey: "review",
  liveCart: true,
  onSubmit,
  stepNumber: Steps.Review,
};

export default Review;
