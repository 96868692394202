import { useState } from "react";

export interface UseDateCarouselQueuedIndex {
  dateCarouselQueuedIndex: number | null | undefined;
  setDateCarouselQueuedIndex: (queuedIndex: number | null | undefined) => void;
}

export default function useDateCarouselQueuedIndex() {
  const [dateCarouselQueuedIndex, setDateCarouselQueuedIndex] = useState<number | null | undefined>(0);

  return {
    dateCarouselQueuedIndex,
    setDateCarouselQueuedIndex,
  };
}