import { APIResponse } from "./getAuthorizedAPIData";
import { Cart } from "./getCartData";

export interface acmeCORSSharedArgs {
  corsURL: string | null;
  publicKey: string | null;
  recaptchaToken?: string | null;
  tenantID: string | null;
}

export interface postCORSCheckoutMainArgs {
  enhancedSecurity?: {
    acmeSessionId: string;
  },
  shoppingCart?: Cart;
  shoppingCartId?: string;
  contactFirstName?: string;
  contactLastName?: string;
  email: string;
  phoneNumber: string;
  contactFullName?: string;
  address1: string;
  address2?: string;
  country: string;
  city: string;
  state: string;
  zipCode: string;
  expDate?: string;
  manualEntryCardNumber?: string;
  cvc?: string;
  ccLastFourDigits?: string;
  creditCardBrand?: string;
  notes?: string;
  billingEmail?: string;
  billingFirstName?: string;
  billingLastName?: string;
  billingPhoneNumber?: string;
  billingAddress1?: string;
  billingAddress2?: string;
  billingCountry?: string;
  billingCity?: string;
  billingZipCode?: string;
  billingState?: string;
  sendEmail?: boolean;
  uuid: string;
}

export type OrderSaleChannel = "online"
  | "pointOfSale"
  | "reseller"
  | "customerRep"
  | "manualEntry"
  | "insideSalesIndividual";

export type OrderCheckInStatus = "NotCheckedIn"
  | "CheckedIn"
  | "PartiallyCheckedIn";

export type OrderItemType = "event"
  | "inventory"
  | "comboEvent"
  | "comboInventory";

export type OrderTicketVoStatus = "Normal"
  | "Rebook"
  | "Refund"
  | "Void";

export interface OrderTicketVo {
  id: string;
  tenantId: string;
  uuid: string;
  checkInDate: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  ticketStatus: OrderTicketVoStatus;
  printed: boolean;
  notes: string;
}

export interface OrderItem {
  itemId: string;
  parentItemId: string;
  comboTemplateId: string;
  comboTemplateName: string;
  eventId: string;
  eventName: string;
  eventStartTime: string;
  ticketingTypeId: string;
  ticketingTypeName: string;
  basicOriginalQuantity: number;
  originalQuantity: number;
  basicQuantity: number;
  quantity: number;
  discountId: string;
  discountCategoryId: string;
  accountCategoryId: string;
  checkedInQuantity: number;
  unitPrice: number;
  discountedUnitPrice: number;
  discountedOriginalAmount: number;
  originalAmount: number;
  discountedAmount: number;
  amount: number;
  balanceAmount: number;
  checkInDate: string;
  itemType: OrderItemType;
  inventoryId: string;
  inventoryName: string;
  ticketVos: OrderTicketVo[];
}

export interface Order {
  id: string;
  tenantId: string;
  organizationId: string;
  visitorId: string;
  tempVisitorId: string;
  email: string;
  contactFirstName: string;
  contactLastName: string;
  zipCode: string;
  phoneNumber: string;
  orderNumber: string;
  discountedOriginalTotalAmount: number;
  totalAmount: number;
  paidAmount: number;
  balanceAmount: number;
  refundAmount: number;
  saleChannel: OrderSaleChannel;
  checkInStatus: OrderCheckInStatus;
  orderExceptionCodeId: string;
  orderExceptionCodeName: string;
  notes: string;
  posUUID: string;
  createdOn: string;
  createdBy: string;
  updatedOn: string;
  updatedBy: string;
  paymentDueDate: string;
  ticketDelivery: string;
  ticketExpireDays: number;
  hideEventDate: boolean;
  legacy: boolean;
  membershipId: number;
  couponId: string;
  ccLastFourDigits: string;
  paymentId: string;
  accountContactId: string;
  orderItems: OrderItem[];
}

export type postCORSCheckoutArgs = acmeCORSSharedArgs & postCORSCheckoutMainArgs;

export default async function postCORSCheckout({
  corsURL,
  publicKey,
  recaptchaToken,
  tenantID,
  uuid,
  ...data
}: postCORSCheckoutArgs): APIResponse<Response> {
  if(!corsURL || !publicKey || !tenantID) {
    return null;
  }

  const url = `${corsURL}/v2/b2c/cors/checkout`;

  const headers: Record<string, string> = {
    "Content-Type": "application/json",
    "x-acme-pub-api-key": publicKey,
    "x-b2c-tenant-id": tenantID,
    "x-acme-request-uuid": uuid,
  };

  if(recaptchaToken) {
    headers["x-acme-captcha-token"] = recaptchaToken;
  }

  const response = await fetch(url, {
    body: JSON.stringify(data),
    headers,
    method: "POST",
  });

  return response;
}