import { Value } from "classnames";
import { ButtonHTMLAttributes, DetailedHTMLProps, useState } from "react";

import Modal, { ModalRenderFunction } from "./Modal";
import classNames from "classnames";

export interface ButtonModalProps extends Omit<DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>, "className"> {
  className?: Value;
  initialIsOpen?: boolean;
  label: string;
  modalClassName?: string;
  modalID?: string;
  render?: ModalRenderFunction;
}

export default function ButtonModal(props: ButtonModalProps) {
  const {
    className,
    initialIsOpen = false,
    label,
    modalClassName,
    modalID,
    render,
    ...buttonProps
  } = props;

  const [isOpen, setIsOpen] = useState(initialIsOpen);

  return (
    <>
      <button
        aria-controls={modalID}
        className={classNames({
          "buttonModal": true,
          "buttonModal--open": isOpen,
          "buttonModal--closed": !isOpen,
        }, className)}
        type="button"
        {...buttonProps}
        onClick={(e) => {
          if(props.onClick) {
            props.onClick(e);
          }

          setIsOpen(true);
        }}
      >{label}</button>
      <Modal
        className={modalClassName}
        id={modalID}
        isOpen={isOpen}
        render={render}
        setIsOpen={setIsOpen}
      />
    </>
  )
}