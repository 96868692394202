import { Form as FormikForm } from "formik";
import { useEffect, useRef, useState } from "react";

import {  } from "../schema";
import { SharedStepProps } from "../steps/Step";
import { getStep, getFirstActiveStep } from "../steps";
import useFormikSchemaContext from "../hooks/useFormikSchemaContext";
import useScheduleCartUpdate from "../hooks/useScheduleCartUpdate";
import useSchema from "../hooks/useSchema";
export interface FormProps extends SharedStepProps {

}

export default function Form(props: FormProps) {
  const formRef = useRef<HTMLFormElement | null>(null);
  const [lastStepNumber, setLastStepNumber] = useState<null | number>()
  const hookedScheduleCartUpdate = useScheduleCartUpdate(props);
  const { values, setFieldTouched, touched } = useFormikSchemaContext();
  const { meta } = useSchema();
  const step = getStep(values.stepNumber);

  useEffect(() => {
    let top = 0;

    if(values.stepNumber !== getFirstActiveStep(values.stepsEnabled)?.stepNumber) {
      if(formRef.current) {
        top = formRef.current.offsetTop;
      }

      const header = document.querySelector<HTMLElement>(".header");

      if(header) {
        top -= header.offsetHeight;
        top = Math.max(top, 0);
      }
    }

    window.scroll({
      behavior: "instant",
      top,
    });
  }, [
    values.stepNumber,
    values.stepsEnabled
  ]);

  useEffect(() => {
    if(values.stepNumber !== lastStepNumber) {

      for(const fieldName in meta.fields) {
        const fieldStepNumber = meta.fields[fieldName].meta?.stepNumber;

        if(fieldStepNumber !== values.stepNumber) {
          continue;
        }

        const fieldTouched = !!((fieldName in touched) && touched[fieldName as keyof(typeof touched)]);
        
        if(fieldTouched) {
          setFieldTouched(fieldName, false);
        }
      }

      setLastStepNumber(values.stepNumber);
    }
  }, [
    lastStepNumber, 
    meta.fields, 
    setFieldTouched, 
    touched, 
    values.stepNumber
  ]);

  if(!step) {
    return "";
  }

  return (
    <FormikForm ref={formRef}>
      <step.Component {...props} {...hookedScheduleCartUpdate} />
    </FormikForm>
  );
}