const getTimeZoneOffset = (timeZone: string) => {
  const dateText = Intl.DateTimeFormat([], {
    timeZone,
    timeZoneName: "longOffset"
  }).format(new Date);
 
  const timezoneString = dateText.split(" ")[1].slice(3) || '+0';
  let timezoneOffset = parseInt(timezoneString.split(':')[0]) * 60;

  if (timezoneString.includes(":")) {
    timezoneOffset = timezoneOffset + parseInt(timezoneString.split(':')[1]);
  }

  return timezoneOffset;
}

export function getSystemTimeZone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export default function getLocalizedDate(date: Date | string = new Date(), targetTimeZone: string = "GMT") {
  if(typeof date === "string") {
    if(!~date.indexOf(":")) {
      date += `T00:00:00`;
    }

    date = new Date(date);
  }
  
  if(targetTimeZone) {
    try {
      const targetTimeZoneOffsetMinutes = getTimeZoneOffset(targetTimeZone);
      const systemTimeZoneOffsetMinutes = getTimeZoneOffset(getSystemTimeZone());
      const totalOffsetMinutes = targetTimeZoneOffsetMinutes - systemTimeZoneOffsetMinutes;

      if(totalOffsetMinutes) {
        date.setTime(date.getTime() - (totalOffsetMinutes * 60 * 1000));
      }
    } catch(error) {
      console.error("Problem translating time zone.", targetTimeZone, error);
    }
  }

  return date;
}