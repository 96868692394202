import { useCallback, useEffect, useMemo, useState } from "react";

import useFormikSchemaContext from "./useFormikSchemaContext";

import { CreateAnyCartItem, CreateCartComboItem, CreateCartDonationItem, CreateCartMembershipItem } from "../fetchers/createCart";
import { CartDonationDonorRecognitionType, CartMembershipCard, CartMembershipGifterInfo } from "../fetchers/getCartData";
import { MemberData } from "../fetchers/getMemberData";
import { Schema } from "../schema";
import { SharedStepProps } from "../steps/Step";
import { VoucherData } from "./useVoucherData";
import { TicketData } from "../fetchers/getTicketData";
import { getStep } from "../steps";
import { MemberLevel } from "../fetchers/getMemberLevelData";

export type UseScheduleCartUpdate = ReturnType<typeof useScheduleCartUpdate>;

export type ScheduleCartUpdate = VoidFunction;

export type useScheduleCartUpdateArgs = Pick<SharedStepProps, 
  "appConfiguration"
  | "cartData"
  | "getMemberLevel"
  | "getMemberLevelByID"
  | "getVoucherDataForTemplateIDs"
  | "isCartDataLoading"
  | "isCartDataValidating"
  | "memberData"
  | "createCart"
  | "deleteCart"
  | "updateCart"
  | "ticketData"
  | "voucherData"
>;

type ActiveData = Omit<Schema, 
  "loginType"
  | "membershipNumber"
  | "membershipLastName"
  | "residentZIPCode"
  | "stepsEnabled"
  | "stepsNumber"
  | "ticketsConfirmed"
  | "paymentRequired"
  | "cardNumber"
  | "securityCode"
  | "expirationMonth"
  | "expirationYear"
  | "recaptchaV2Token"
  | "recaptchaV2Valid"
  | "recaptchaV3Token"
  | "recaptchaV3Valid"
> & {
  memberData: MemberData | null | undefined;
}

export const getVoucherCodeDistribution = ({
  ticketData,
  values,
  voucherData,
}: {
  ticketData: TicketData | null | undefined,
  values: Schema,
  voucherData: VoucherData | null | undefined
}) => {
  const distribution: Record<number, {
    voucherCode: string,
    couponCode: string
  }[]> = {};

  if(!values.tickets.length) {
    return distribution;
  }

  if(!ticketData) {
    return distribution;
  }

  if(!voucherData) {
    return distribution;
  }

  let availableVoucherCodes = values
    .voucherCodes
    .map(voucherCode => voucherCode || "")
    .filter(voucherCode => voucherCode);

  if(!availableVoucherCodes.length) {
    return distribution;
  }

  if(!values.tickets.length || !values.tickets.filter(ticket => ticket.quantity).length) {
    return distribution;
  }

  const ticketPrices = values.tickets.reduce((obj, tv, index) => {
    const price = ticketData
      .find(t => t.template_id === tv.eventTemplateID)
      ?.prices
      .find(p => p.applies_to_id === tv.ticketingTypeID)
      ?.price || 0;

    obj[tv.eventTemplateID + '-' + tv.ticketingTypeID] = {
      price,
      index
    };

    return obj;
  }, {} as Record<string, {
    price: number;
    index: number;
  }>);

  // Apply vouchers to more expensive tickets first.
  const sortedTicketValues = values.tickets
    .map((ticket, index) => ({
      ticket,
      index
    }))
    .sort((a, b) => (
      ticketPrices[
        b.ticket.eventTemplateID 
        + '-' 
        + b.ticket.ticketingTypeID
      ].price || 0
    ) - (
      ticketPrices[
        a.ticket.eventTemplateID 
        + '-' 
        + a.ticket.ticketingTypeID
      ].price || 0
    ));

  sortedTicketValues.forEach(({ ticket, index: ticketIndex }) => {
    if(!ticket.quantity) {
      return;
    }
  
    if(ticket.isComboTicket) {
      return;
    }

    const usedVoucherCodes: string[] = [];

    for(const voucherCode of availableVoucherCodes) {
      const data = voucherData[voucherCode];

      if(!data) {
        continue;
      }
      
      if(data.override_price) {
        if(!data.template_ids.includes(ticket.eventTemplateID)) {
          continue;
        }

        const ticketCategory = ticketData
          ?.find(t => t.event_id === ticket.eventID)
          ?.prices
          ?.find(p => p.applies_to_id === ticket.ticketingTypeID)
          ?.applies_to;

        if(data.acme_ticket_category !== ticketCategory && !data.acme_ticket_category?.includes(ticketCategory || "")) {
          continue;
        }
      }

      if(!data.acme_discount_code) {
        continue;
      }

      usedVoucherCodes.push(voucherCode);

      if(usedVoucherCodes.length === ticket.quantity) {
        break;
      }
    }

    if(usedVoucherCodes.length) {
      availableVoucherCodes = availableVoucherCodes.filter(voucherCode => !usedVoucherCodes.includes(voucherCode));

      distribution[ticketIndex] = usedVoucherCodes.map(voucherCode => ({
        voucherCode,
        couponCode: voucherData[voucherCode]!.acme_discount_code!
      }));
    }
  });
  
  return distribution;
}

export default function useScheduleCartUpdate({
  appConfiguration,
  cartData,
  getMemberLevel,
  getMemberLevelByID,
  getVoucherDataForTemplateIDs,
  isCartDataLoading,
  isCartDataValidating,
  memberData,
  createCart,
  deleteCart,
  updateCart,
  ticketData,
  voucherData,
}: useScheduleCartUpdateArgs) {
  const { values } = useFormikSchemaContext();
  const [updateScheduled, setUpdatedScheduled] = useState(false);

  const compileCartData = () => {
    const data: ActiveData = {
      ...values,
      memberData,
    };

    return JSON.stringify(data);
  };

  const compiledCartData = compileCartData();
  const [lastCompiledCartData, setLastCompiledCartData] = useState("");
  const [lastSubmittedCartData, setLastSubmittedCartData] = useState("");

  const voucherCodeDistribution = useMemo(() => {
    return getVoucherCodeDistribution({
      ticketData,
      values,
      voucherData
    });
  }, [ticketData, values, voucherData]);

  useEffect(() => {
    const step = getStep(values.stepNumber);

    if(step.liveCart && (lastCompiledCartData !== compiledCartData)) {
      setLastCompiledCartData(compiledCartData);
      setUpdatedScheduled(true);
    }
  }, [
    compiledCartData,
    lastCompiledCartData,
    values.stepNumber
  ]);

  useEffect(() => {
    let updateTimeout: NodeJS.Timeout | null = null;
    
    if(
      (updateScheduled)
      && !isCartDataLoading
      && !isCartDataValidating
    ) {
      const doUpdate = async () => {
        setUpdatedScheduled(false);

        const data: ActiveData = JSON.parse(compiledCartData);
        
        const {
          addOns,
          customDonationAmount,
          donationID,
          donationNotes,
          donationType,
          donorRecognitionType,
          hasPromoCode,
          memberData,
          membershipAddonLevelID,
          membershipLevelSlug,
          membershipTarget,
          promoCode,
          tickets,
        } = data;
        
        const comboItems: CreateCartComboItem[] = [];
        let items: CreateAnyCartItem[] = [];
        const couponCodes: string[] = [];

        if(donationID) {
          const selectedDonation = appConfiguration?.addons.find(addon => addon.addon_id === donationID);

          if(selectedDonation && (selectedDonation.type !== "donation" || customDonationAmount)) {
            const donationPrice = donationType === "donation" ? parseFloat(customDonationAmount) : selectedDonation.price
            
            let resolvedDonorRecognitionType: CartDonationDonorRecognitionType;

            switch(donorRecognitionType) {
              case "self":
              case "anonymous":
              case "organization":
                resolvedDonorRecognitionType = donorRecognitionType;
                break;
              default:
                throw new Error("Invalid donor recognition type: " + donorRecognitionType);
            }

            const donationItem: CreateCartDonationItem = {
              amount: donationPrice,
              donationDetail: {
                donorRecognition: donorRecognitionType === "organization" ? donationNotes : donorRecognitionType,
                donorRecognitionType: resolvedDonorRecognitionType,
                notes: donationNotes,
              },
              inventoryId: donationID,
              itemType: "Inventory",
              quantity: 1,
              unitPrice: donationPrice,
            };
          
            items.push(donationItem);
          }
        }

        if(addOns.length) {
          items = items.concat(addOns.map(({ inventoryId, quantity }) => {
            const addOnPrice = appConfiguration?.addons.find(addon => addon.addon_id === inventoryId)?.price || 0;

            return {
              amount: addOnPrice * quantity,
              inventoryId,
              itemType: "Inventory",
              quantity,
              unitPrice: addOnPrice,
              retailUnitPrice: addOnPrice,
            };
          }));
        }
      
        if(tickets?.length) {
          tickets.forEach((ticket, ticketIndex) => {
            if(!ticket.quantity) {
              return;
            }
  
            if(ticket.isComboTicket) {
              let comboItem = comboItems.find(comboItem => comboItem.comboTemplateId === ticket.comboTemplateID);
                
              if(!comboItem) {
                comboItem = {
                  comboTemplateId: ticket.comboTemplateID!,
                  events: [],
                  tickets: [],
                };

                comboItems.push(comboItem);
              }

              let comboEvent = comboItem.events?.find(event => event.eventTemplateId === ticket.eventTemplateID && event.eventId === ticket.eventID);
              
              if(!comboEvent) {
                comboEvent = {
                  eventId: ticket.eventID,
                  eventTemplateId: ticket.eventTemplateID,
                }

                comboItem.events?.push(comboEvent);
              }

              if(!comboItem.tickets?.find(t => t.ticketTypeId === ticket.ticketingTypeID)) {
                comboItem.tickets?.push({
                  eventTemplateId: ticket.eventTemplateID,
                  quantity: ticket.quantity,
                  ticketTypeId: ticket.ticketingTypeID,
                });
              }
            } else {
              let quantity = ticket.quantity;
              const distribution = voucherCodeDistribution[ticketIndex];

              if(distribution?.length) {
                distribution.forEach(voucher => {
                  items.push({
                    couponCodes: [voucher.couponCode!],
                    eventId: ticket.eventID,
                    itemType: ticket.comboTemplateID ? "ComboEvent" : "Event",
                    quantity: 1,
                    ticketingTypeId: ticket.ticketingTypeID,
                  });

                  quantity--;
                });
              }
  
              if(quantity) {
                items.push({
                  eventId: ticket.eventID,
                  itemType: ticket.comboTemplateID ? "ComboEvent" : "Event",
                  quantity,
                  ticketingTypeId: ticket.ticketingTypeID,
                });
              }
            }
          });
        }

        const addCartMembershipItem = (memberLevel: MemberLevel | undefined) => {
          if(!memberLevel) {
            return;
          }

          const membershipCards: CartMembershipCard[] = [];

          membershipCards.push({
            cardType: "primary",
            name: [data.primaryMemberFirstName, data.primaryMemberLastName].filter(v => v).join(" "),
            firstName: data.primaryMemberFirstName,
            lastName: data.primaryMemberLastName,
            streetAddress1: data.primaryMemberStreetAddress1,
            streetAddress2: data.primaryMemberStreetAddress2,
            city: data.primaryMemberCity,
            state: data.primaryMemberState,
            zipCode: data.primaryMemberZIP,
            country: data.primaryMemberCountry,
            email: data.email,
            phoneNumber: data.primaryMemberPhoneNumber,
          });

          if(data.secondaryMemberFirstName) {
            membershipCards.push({
              ...membershipCards[0],
              cardType: "secondary",
              name: [data.secondaryMemberFirstName, data.secondaryMemberLastName].filter(v => v).join(" "),
              firstName: data.secondaryMemberFirstName,
              lastName: data.secondaryMemberLastName,
            });
          }

          const isGift = membershipTarget !== "self";

          const gifterInfo: CartMembershipGifterInfo | undefined = isGift
            ? {
              customerSource: "acme",
              email: data.email,
              firstName: data.firstName,
              lastName: data.lastName,
              phoneNumber: data.phoneNumber,
              address: [{
                streetAddress1: data.streetAddress1,
                streetAddress2: data.streetAddress2,
                city: data.city,
                state: data.state,
                zipCode: data.zip,
                country: data.country,
                type: "primary",
                isPrimary: false,
              }],
              version: 0,
              obfuscated: false,
            }
            : undefined;

          const membershipCategoryId = memberLevel.id;
          const membershipOffering = memberLevel.offerings[0];
          const membershipOfferingId = membershipOffering.id;
          const membershipPriceInfo = membershipOffering.priceLists[0].prices[0];
          const pricePointId = membershipPriceInfo.personType.id;
          const membershipPrice = membershipPriceInfo.price;

          const membershipItem: CreateCartMembershipItem = {
            itemType: "MembershipPurchase",
            membershipInfo: {
              membershipCategoryId,
              membershipOfferingId,
              pricePointId,
              membershipCards,
              waiveBenefits: false,
              isGift,
              gifterInfo,
              notifyGiftRecipient: !!gifterInfo,
            },
            quantity: 1,
            unitPrice: membershipPrice,
            retailUnitPrice: membershipPrice,
            amount: membershipPrice,
          };

          items.push(membershipItem);
        };
        
        addCartMembershipItem(getMemberLevel(membershipLevelSlug));
        addCartMembershipItem(getMemberLevelByID(membershipAddonLevelID));
        
        if(hasPromoCode.length && promoCode) {
          couponCodes.push(promoCode);
        }

        let submittedCartData = "";

        if(cartData?.id) {
          if(items.length || comboItems?.length) {
            submittedCartData = JSON.stringify({
              ...cartData,
              comboItems: comboItems.length ? comboItems : undefined,
              couponCodes: couponCodes.length ? couponCodes : undefined,
              items,
              saleChannel: "online",
            });

            if(submittedCartData !== lastSubmittedCartData) {
              setLastSubmittedCartData(submittedCartData);
              
              await updateCart({
                ...cartData,
                comboItems: comboItems.length ? comboItems : undefined,
                couponCodes: couponCodes.length ? couponCodes : undefined,
                items,
                saleChannel: "online",
              });
            }
          } else {
            setLastSubmittedCartData(submittedCartData);
            await deleteCart(cartData.id);
          }
        } else {
          if(items.length || comboItems?.length) {
            submittedCartData = JSON.stringify({
              couponCodes,
              comboItems: comboItems.length ? comboItems : undefined,
              items,
              membershipIds: memberData?.primaryCardHolder?.membershipId
                ? [memberData.primaryCardHolder.membershipId.toString()]
                : undefined,
              saleChannel: "online"
            });
          
            if(submittedCartData !== lastSubmittedCartData) {
              setLastSubmittedCartData(submittedCartData);

              await createCart({
                couponCodes,
                comboItems: comboItems.length ? comboItems : undefined,
                items,
                membershipIds: memberData?.primaryCardHolder?.membershipId
                  ? [memberData.primaryCardHolder.membershipId.toString()]
                  : undefined,
                saleChannel: "online"
              });
            }
          }
        }
      };

      updateTimeout = setTimeout(doUpdate, 500);
    }

    return () => {
      if(updateTimeout) {
        clearTimeout(updateTimeout);
      }
    }
  }, [
    appConfiguration?.addons,
    cartData,
    compiledCartData,
    createCart,
    deleteCart,
    getVoucherDataForTemplateIDs,
    getMemberLevel,
    isCartDataLoading,
    isCartDataValidating,
    lastSubmittedCartData,
    updateCart,
    updateScheduled,
    voucherCodeDistribution
  ]);

  const scheduleCartUpdate = useCallback(() => {
    setUpdatedScheduled(true);
  }, []);

  return {
    scheduleCartUpdate,
    voucherCodeDistribution
  }
}