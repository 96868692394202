import getAuthorizedAPIData, { APIResponse, getAuthorizedAPIDataSharedArgs } from "./getAuthorizedAPIData";

export type AddonType = "doNotTrack"
  | "consumable"
  | "resource"
  | "donation"
  | "fixedDonation"
  | "membershipCardReplacement";

export interface AppAddon {
  active: boolean;
  addon_id: string;
  description: string;
  display_order: number;
  id: number;
  image: string;
  name: string;
  sku: string;
  price: number;
  quantity: number;
  sales_channels: string[];
  type: AddonType;
}

export interface AppComboTemplateAddon {
  addon_id: string;
  description: string;
  image: string;
  label: string;
  name: string;
  price: number;
  price_is_each: 0 | 1;
  priority: number;
  quantity: number;
  quantity_is_exact: 0 | 1;
}

export interface AppComboTemplateDiscount {
  applies_to_id: string;
  is_percent_based: 0 | 1;
  value: number;
}

export interface AppComboTemplatePrice {
  applies_to: string | null;
  applies_to_id: string | null;
  price: string | number;
}

export interface AppComboTemplateEntrySummary {
  id: number;
  combo_template_entry_id: number;
  template_id: string;
  name: string;
  description: string;
  short_description: string;
  created_at: string;
  updated_at: string;
}

export interface AppComboTemplateEntry {
  app_configuration_id: number;
  description: string | null;
  entry_index: number;
  image: string | null;
  label: string | null;
  priority: number;
  quantity: number;
  template_ids: string[];
  summaries?: AppComboTemplateEntrySummary[];
}

export interface AppMembershipAddon {
  id: number;
  membership_level_id: string;
  label: string | null;
  description: string | null;
  priority: number | null;
  image: string | null;
}

export interface AppComboTemplate {
  id: number;
  organization_id: number;
  name: string;
  template: string;
  created_at: string;
  updated_at: string;
  addons: AppComboTemplateAddon[];
  discounts: AppComboTemplateDiscount[];
  entries: AppComboTemplateEntry[];
  prices: AppComboTemplatePrice[];
}

export type AppContent = Record<string, string>;

export interface AppLoginSection {
  id: number;
  app_configuration_id: number;
  title: string | null;
  content: string | null;
  priority: number | null;
  created_at: string;
  updated_at: string;
}

export interface AppTicketCategory {
  id: number;
  app_configuration_id: number;
  template_ids: string[];
  label: string | null;
  description: string | null;
  image: string | null;
  priority: number | null;
  created_at: string;
  updated_at: string;
}

export interface AppTicketType {
  applies_to_id: string;
  combo_template_id: string | null;
  display_order: number | null;
  description: string | null;
  label: string | null;
  show_members: boolean | null;
  show_non_members: boolean | null;
  show_non_residents: boolean | null;
  show_residents: boolean | null;
  template_id: string | null;
}

export interface AppConfiguration {
  acme_api_url: string;
  acme_merchant_id: string | null;
  acme_publishable_key: string | null;
  acme_tenant_id: string;
  addons: AppAddon[]; 
  allow_guest_access: boolean;
  allow_member_login: boolean;
  allow_promo_codes: boolean | null;
  allow_resident_login: boolean;
  combo_template_ids: string[] | string;
  combo_templates: AppComboTemplate[];
  content: AppContent;
  cors_key: string;
  end_date: string | null,
  id: number,
  locale: string,
  login_sections: AppLoginSection[],
  member_join_link: string | null,
  membership_addons?: AppMembershipAddon[];
  recaptcha_secret_key_v2: string | null,
  recaptcha_site_key_v2: string | null,
  recaptcha_secret_key_v3: string | null,
  recaptcha_site_key_v3: string | null,
  resident_locations: string[] | null;
  show_additional_login_option: boolean;
  allow_vouchers: boolean | null;
  show_date_selection: number,
  show_time_selection: number,
  source_time_zone: string,
  start_date: string | null,
  summary_category: string | null,
  summary_template: string | null,
  member_summary_category: string | null,
  resident_summary_category: string | null,
  template_ids: string[],
  ticket_categories: AppTicketCategory[],
  ticket_types: AppTicketType[],
  use_3ds: boolean,
}

export interface getAppConfigurationArgs extends getAuthorizedAPIDataSharedArgs {
  appConfigurationID: number;
}

export default function getAppConfiguration({
  apiURL,
  appConfigurationID,
  authorizationToken,
}: getAppConfigurationArgs): APIResponse<AppConfiguration> {
  const endPoint = `api/app-configuration/${appConfigurationID}`;

  return getAuthorizedAPIData({
    apiURL,
    authorizationToken,
    endPoint,
  });
}