import classNames from "classnames";
import { PropsWithChildren, ReactNode, useEffect, useRef } from "react";
import { createPortal } from "react-dom";

export type ModalProps = PropsWithChildren<{
  className?: string;
  disableCloseOnOutsideClick?: boolean;
  id?: string;
  isOpen?: boolean;
  render?: ModalRenderFunction;
  setIsOpen?: (isOpen: boolean) => void;
}>;

export type ModalRenderFunctionArgs = Omit<ModalProps, "children" | "isOpen" | "render"> & {
  close: VoidFunction;
};

export type ModalRenderFunction = (args: ModalRenderFunctionArgs) => ReactNode;

const bodyClass = "body--containsModal";

export default function Modal({
  children,
  disableCloseOnOutsideClick = false,
  isOpen = false,
  render,
  ...renderProps
}: ModalProps) {
  const {
    className,
    id,
    setIsOpen,
  } = renderProps;

  const dialogRef = useRef<HTMLDialogElement | null>(null);

  useEffect(() => {
    const onBodyClick = (e: MouseEvent) => {
      if(setIsOpen && !disableCloseOnOutsideClick && !dialogRef.current?.contains(e.target as Node)) {
        setIsOpen(false);
      }
    };

    if(isOpen) {
      setTimeout(() => {
        document.body.classList.add(bodyClass);
        document.body.addEventListener("click", onBodyClick);
      }, 0);
    } else {
      document.body.classList.remove(bodyClass);
      document.body.removeEventListener("click", onBodyClick);
    }

    return () => {
      document.body.classList.remove(bodyClass);
      document.body.removeEventListener("click", onBodyClick);
    }
  }, [disableCloseOnOutsideClick, isOpen, setIsOpen]);

  return createPortal(
    <dialog
      className={classNames({
        "modal": true,
        "modal--open": isOpen,
        "modal--closed": !isOpen,
        [className + ""]: className,
      })}
      id={id}
      open={isOpen || undefined}
      ref={dialogRef}
    >
      {children}
      {render && isOpen && render({
        ...renderProps,
        close: () => {
          if(setIsOpen) {
            setIsOpen(false);
          }
        }
      })}
    </dialog>,
    document.body,
    id
  );
}