import { useEffect, useState } from "react";
import Calendar from "react-calendar";

import { StepComponentProps } from "../Step";

import { ButtonModal } from "../../components/Modal";
import getLocalizedText from "../../formatters/getLocalizedText";
import formatSQLDate from "../../formatters/formatSQLDate";

export default function ScheduleAheadButton({
  appConfiguration,
  dailyTicketData,
  readFrom,
  setDateCarouselQueuedIndex,
  setTicketDate,
  setTicketEvents,
  ticketDate,
}: StepComponentProps) {
  const [queuedDate, setQueuedDate] = useState<Date | null>(null);

  useEffect(() => {
    if(queuedDate && dailyTicketData?.length) {
      const formattedQueuedDate = formatSQLDate(queuedDate);
      const index = dailyTicketData?.findIndex(day => day.ticket_date === formattedQueuedDate);

      if(~index) {
        setDateCarouselQueuedIndex(index);
        setTicketDate(formattedQueuedDate);
        setTicketEvents([]);
        setQueuedDate(null);
      }
    }
  }, [
    dailyTicketData, 
    queuedDate, 
    setDateCarouselQueuedIndex, 
    setTicketDate, 
    setTicketEvents
  ]);

  return (
    <ButtonModal
      className="scheduleAheadButton"
      label={getLocalizedText(appConfiguration, "scheduleAhead", "Schedule Ahead")}
      render={({ close }) => (
        <>
          <Calendar
            onChange={(selectedDate) => {
              if(selectedDate && selectedDate instanceof Date) {
                close();
                setQueuedDate(selectedDate);
                readFrom(selectedDate, 7, true);
              }
            }}
            locale={appConfiguration?.locale}
            minDate={appConfiguration?.start_date ? new Date(appConfiguration.start_date) : new Date()}
            value={ticketDate ? new Date(ticketDate) : undefined}
          />
          <button
            onClick={close}
          >Close</button>
        </>
      )}
    />
  );
}